import { APP_AT_LEAST_ONE_OF_REQUIRED_USER_ROLES, APP_REQUIRED_USER_ROLES } from '@core';

/*
 * Check the roles of the user.
 *
 * All roles in the array we compare against must be present in the user roles.
 *
 * If the **roles array** we compare against our user roles **is empty**,
 * we will always return `true` since the user will have enough role permissions.
 *
 * If **at least one** of the user roles **is not present** in the roles array, we return `false`.
 *
 * @params roles: string[]
 * */
export const hasAllRequiredUserRoles = (roles: string[] = []) =>
  APP_REQUIRED_USER_ROLES.length
    ? APP_REQUIRED_USER_ROLES.every(value => roles.includes(value))
    : true;

/*
 * Check the roles of the user.
 *
 * At least one of the roles in the array we compare against must be present in the user roles.
 *
 * If the **roles array** we compare against our user roles **is empty**,
 * we will always return `true` since the user will have enough role permissions.
 *
 * If **none** of the user roles are **present** in the roles array, we return `false`.
 *
 * @params roles: string[]
 * */
export const hasAtLeastOneRequiredUserRole = (roles: string[] = []) =>
  APP_AT_LEAST_ONE_OF_REQUIRED_USER_ROLES.length
    ? APP_AT_LEAST_ONE_OF_REQUIRED_USER_ROLES.some(value => roles.includes(value))
    : true;

import {
  AppRoutes,
  AppSplashScreen,
  authUserAtom,
  DATA_STATE,
  ForbiddenPage,
  useDocumentTitle,
} from '@codeer/handypark-web-common';
import {
  APP_ROUTES,
  hasAllRequiredUserRoles,
  hasAtLeastOneRequiredUserRole,
  MainError,
  PageLayout,
  useMain,
} from '@core';
import { useAtomValue } from 'jotai';

import { FC } from 'react';

export const Main: FC = () => {
  /* --- ATOMS --- */
  const authUser = useAtomValue(authUserAtom);

  /* --- HOOKS --- */
  useDocumentTitle();
  const { mainDataState, mainError } = useMain();

  if (mainDataState === DATA_STATE.LOADING) {
    return <AppSplashScreen />;
  }

  if (mainDataState === DATA_STATE.ERROR) {
    return <MainError error={mainError} />;
  }

  // Check roles of the authenticated user
  if (
    !hasAllRequiredUserRoles(authUser?.roles) ||
    !hasAtLeastOneRequiredUserRole(authUser?.roles)
  ) {
    return (
      <PageLayout>
        <ForbiddenPage />
      </PageLayout>
    );
  }

  const routes = APP_ROUTES();

  return (
    <PageLayout>
      <AppRoutes appRoutes={routes} />
    </PageLayout>
  );
};

import { ApiError, initializeApiClient, initializeProxyClient } from '@codeer/handypark-common';
import { DATA_STATE, useRemoteTranslations } from '@codeer/handypark-web-common';
import { useMemo } from 'react';

export const useMain = () => {
  /* --- API CLIENTS --- */
  initializeApiClient({
    baseURL: '/api',
    maxRedirects: 0,
  });
  initializeProxyClient({ baseURL: '/proxy/v1', maxRedirects: 0 });

  /* --- HOOKS --- */
  const { hasFetchedRemoteTranslations } = useRemoteTranslations();

  /* --- DATA --- */
  const isLoading = useMemo(() => {
    return !hasFetchedRemoteTranslations;
  }, [hasFetchedRemoteTranslations]);

  const error: ApiError | undefined = useMemo(() => {
    return undefined;
  }, []);

  const dataState = useMemo(() => {
    if (isLoading) {
      return DATA_STATE.LOADING;
    }
    if (error) {
      return DATA_STATE.ERROR;
    }
    return DATA_STATE.OK;
  }, [isLoading, error]);

  return {
    mainDataState: dataState,
    mainError: error,
  };
};

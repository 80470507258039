import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  APP_BUTTON_VARIANTS,
  AppButton,
  COLOR_SCHEMES,
  ItsMe,
  TAppButtonProps,
} from '@codeer/handypark-web-common';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export const AppButtonItsMe: FC<TAppButtonProps> = ({ children, ...rest }): ReactElement => {
  /* --- TRANSLATIONS --- */
  const { t } = useTranslation(T_NAMESPACES.COMPONENTS, { keyPrefix: 'button.sign-in' });

  return (
    <AppButton
      variant={APP_BUTTON_VARIANTS.SOLID}
      colorScheme={COLOR_SCHEMES.ITS_ME}
      leftIcon={<ItsMe aria-hidden={true} boxSize={'1em'} />}
      {...rest}
    >
      {t('its-me')}
    </AppButton>
  );
};
